import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TopImage from "../components/topImage";
import { RenderAst } from "../components/styledComponents";

export default function ProjectDetails({ data }) {
  const { htmlAst, featuredImg, frontmatter } = data.markdownRemark;
  const topImage = featuredImg.childImageSharp.gatsbyImageData;
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <TopImage topImage={topImage} title={frontmatter.title} />
      <div className="container mx-auto flex flex-wrap justify-center w-9/12 pt-10">
        {RenderAst(htmlAst)}
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ProjectDetails($slug: String) {
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
      }
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { fit: COVER, cropFocus: CENTER }
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;
